/* eslint-disable no-console */
import { useRecoilValue } from 'recoil';
import { jackRecipientDetails, jackTranstionKeys } from './states/jackstate';
import { useEffect } from 'react';

export const SenderVerificationProcess = ({onSuccessCall}:any) => {
	const jackTranstion = useRecoilValue(jackTranstionKeys);
    const jackRecipient = useRecoilValue(jackRecipientDetails)

	const { sender } = jackTranstion ?? {};
	// API CALL here

	useEffect(() => {
		const checkUser = setInterval(async () => {
			const apiUrl = `https://api.stage.satschel.com/v2/rocket/customer/code/${sender}`;
			const headers = {
				// Include any headers if needed
			};
			fetch(apiUrl, {
				method: 'GET',
				headers: headers,
			})
				.then((response) => {
					// Check if the request was successful (status code 200)
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((responseData) => {
					// Update the state with the response data
					if (responseData?.customer?.status === 'completed' || responseData?.customer?.status === 'rejected' ) {
						const fbAmount = (
							document
								?.querySelector(
									'body > banno-web > bannoweb-layout > bannoweb-zelle-send'
								)
								?.shadowRoot?.querySelector?.('#frmEnterAmount')
								?.shadowRoot?.querySelector?.('#amount') as
								| HTMLInputElement
								| undefined
						)?.value?.toString();
						const amount = fbAmount;

						const recipientPhone = (jackRecipient?.user?.phone_no ?? "").replace("+1", '').replace("+91" ,'');
						const senderPhone = (responseData?.user?.phone_no ?? "").replace("+1", '').replace("+91", '');
						const payload = {
							sender: responseData?.user?.name ?? 'Austin Trombley',
							recipient: jackRecipient?.user?.name, 
							amount: amount ?? '1',
							currency: 'USD',
							location: {
								sender: responseData?.customer?.device_info?.location ?? {},
								recipient: jackRecipient?.customer?.device_info?.location ?? {}								,
							},
							image: responseData?.customer?.user_image,
							recipient_img: jackRecipient?.customer?.user_image,
							recipient_id: jackRecipient?.customer?.id,
							recipientEmail : jackRecipient?.user?.email_id ?? "",
							senderEmail : responseData?.user?.email_id ?? "Austin.Trombley@satschel.com",
							senderIP : responseData?.customer?.device_info?.ip ?? "" ,
							senderOS : responseData?.customer?.device_info?.['user-agent'] ?? "IOS",
							recipientIP: jackRecipient?.customer?.device_info?.ip ?? "" ,
							recipientOS: jackRecipient?.customer?.device_info?.['user-agent'] ?? "IOS",
							recipientMatchScore: jackRecipient?.transaction?.recipient?.match_score ?? 1,
							senderMatchScore: responseData?.transaction?.sender?.match_score ?? 1 ,
							transactionStatus: responseData?.customer?.status === 'completed' ? "success" :"failed", 
							recipientPhone,
							senderPhone,
						};
						console.log('payload--->', payload)
						setTimeout(()=>{
						onSuccessCall(payload);
						},2000)
						clearInterval(checkUser);
					}
				})
				.catch((error) => {
					console.error('Error making GET request:', error.message);
				});
		}, 2000);
	}, []);

	return (
		<>
			<simplici-verfication verfication_id={sender} />
		</>
	);
};
